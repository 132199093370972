import { ready } from "../lib/animation";

ready(function () {
  const toasts = Array.prototype.slice.call(
    document.getElementsByClassName("toast")
  ) as HTMLElement[];
  const duration = 3500;

  function showToast(toast: HTMLElement) {
    toast.classList.remove("hidden");
    setTimeout(function () {
      toast.classList.remove("fadeout");
    }, 10);
    setTimeout(function () {
      toast.classList.add("fadeout");
    }, duration - 500);
    setTimeout(function () {
      toast.classList.add("hidden");
    }, duration - 100);
  }

  toasts.forEach(function (toast: HTMLElement) {
    const index = toasts.indexOf(toast);
    toast.classList.add("fadeout");
    toast.classList.add("hidden");
    setTimeout(function () {
      showToast(toast);
    }, duration * index);
  });
});
