import { ready } from "../lib/animation";

ready(function () {
  const header = document.querySelector("header");
  const toggle = header.querySelector(".photo");

  if (toggle) {
    toggle.addEventListener("click", function (evt: MouseEvent) {
      evt.preventDefault();
      header.classList.toggle("show-navigation");
    });
  }
});
