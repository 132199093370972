import React, { useState } from "react";

interface Participant {
  id: number;
  name: string;
  job_title: string;
  company: string;
  country: string;
  business_activity: string;
  placeholder: string;
  url: string;
  photo?: string;
}

interface Props {
  countries: string[];
  participants: Participant[];
  business_activities: string[];
}

function fuzzymatch(str: string, query: string): boolean {
  const pattern = query
    .toLowerCase()
    .split(" ")
    .reduce((a, b) => a + ".*" + b);
  return new RegExp(pattern).test(str.toLowerCase());
}

function matchesSearch(participant: Participant, query: string): boolean {
  const str = [
    participant.name,
    participant.job_title,
    participant.company,
    participant.country,
    participant.business_activity
  ].join(" ");
  return fuzzymatch(str, query);
}

export default function Delegates({
  countries,
  participants,
  business_activities
}: Props) {
  const [search, setSearch] = useState<string>("");
  const [country, setCountry] = useState<string | undefined>(undefined);
  const [businessActivity, setBusinessActivity] = useState<string | undefined>(
    undefined
  );

  const countryOptions = (): string[] => {
    return ["Norway", "International"].concat(
      countries.filter((c) => c !== "Norway")
    );
  };

  const filterParticipants = (participant: Participant): boolean => {
    if (search && !matchesSearch(participant, search)) {
      return false;
    }

    if (
      businessActivity &&
      participant.business_activity !== businessActivity
    ) {
      return false;
    }

    if (
      (country === "International" && participant.country === "Norway") ||
      (country &&
        country !== "International" &&
        participant.country !== country)
    ) {
      return false;
    }

    return true;
  };

  const renderFilters = () => {
    return (
      <div className="filters">
        <input
          name="search"
          type="text"
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="selects">
          <div className="select-wrapper">
            <select name="country" onChange={(e) => setCountry(e.target.value)}>
              <option value="">All countries</option>
              {countryOptions().map((c) => (
                <option key={`country-${c}`} value={c}>
                  {c}
                </option>
              ))}
            </select>
          </div>
          <div className="select-wrapper">
            <select
              name="business-activity"
              onChange={(e) => setBusinessActivity(e.target.value)}>
              <option value="">All activities</option>
              {business_activities.map((a) => (
                <option key={`business-activity-${a}`} value={a}>
                  {a}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  };

  const participantsList = participants.filter(filterParticipants);

  return (
    <section className="participants card">
      {renderFilters()}
      <div className="header-row">
        <div className="delegate">Delegate</div>
        <div className="company">Company</div>
        <div className="business-activity">Business activity</div>
      </div>
      {participantsList.map((p) => (
        <div key={p.id} className="participant">
          <div className="delegate">
            <div className="photo">
              {p.photo ? (
                <img src={p.photo} alt="Profile picture" />
              ) : (
                <div className={`photo-placeholder ${p.placeholder}`} />
              )}
            </div>
            <b>
              <a href={p.url}>{p.name}</a>
            </b>
            <br />
            {p.job_title}
          </div>
          <div className="company">
            <b>{p.company}</b>
            <br />
            {p.country}
          </div>
          <div className="business-activity">{p.business_activity}</div>
        </div>
      ))}
      {participantsList.length === 0 && (
        <div className="no-matches">
          <p>No delegates matching your query were found.</p>
        </div>
      )}
    </section>
  );
}
