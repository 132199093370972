// Entry point for the build script in your package.json

// Rails UJS
import Rails from "@rails/ujs";
Rails.start();

// React
import { FC } from "react";
import * as Components from "./components";
import ReactRailsUJS from "react_ujs";
ReactRailsUJS.getConstructor = (className: string) =>
  Components[className] as FC;

import "./lib/animation";
import "./frontend/background";
import "./frontend/file_drop";
import "./frontend/mobile_nav";
import "./frontend/toast";
