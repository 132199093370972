import { ready } from "../lib/animation";

ready(function () {
  const backgroundCount = 8;
  const body = document.getElementsByClassName("background-image")[0];

  if (body) {
    try {
      // Start on a random background
      if (!localStorage.backgroundId) {
        localStorage.backgroundId = Math.floor(
          Math.random() * backgroundCount + 1
        );
      }

      localStorage.backgroundId =
        (parseInt(localStorage.backgroundId as string, 10) % backgroundCount) +
        1;

      body.classList.add("bg-" + localStorage.backgroundId);
    } catch {
      body.classList.add(
        "bg-" + Math.floor(Math.random() * backgroundCount + 1)
      );
    }
  }
});
