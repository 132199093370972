type ReadyHandler = () => void;

const readyHandlers: ReadyHandler[] = [];

function handleState() {
  if (["interactive", "complete"].indexOf(document.readyState) > -1) {
    while (readyHandlers.length > 0) {
      readyHandlers.shift()();
    }
  }
}

document.onreadystatechange = handleState;

export function ready(handler: ReadyHandler) {
  readyHandlers.push(handler);
  handleState();
}
